@use '@angular/material' as mat;

$light-y4-primary-palette: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$light-y4-accent-palette: (
  50: #f4e5f0,
  100: #e3bfda,
  200: #d094c2,
  300: #bd69aa,
  400: #af4897,
  500: #a12885,
  600: #99247d,
  700: #8f1e72,
  800: #851868,
  900: #740f55,
  A100: #ffa7e2,
  A200: #ff74d0,
  A400: #ff41bf,
  A700: #ff28b7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$light-y4-primary: mat.m2-define-palette(
  $light-y4-primary-palette,
  500,
  300,
  700
);
$light-y4-accent: mat.m2-define-palette(
  $light-y4-accent-palette,
  500,
  300,
  700
);
$light-y4-warn: mat.m2-define-palette(mat.$m2-red-palette);

@font-face {
  font-family: 'Avenir-LT';
  src: url(../../../../assets/themes/theme-light-y4/Avenir-LT-Std-65-Medium.otf);
}
$light-y4-theme-font: 'Avenir-LT';

$light-y4-typography: mat.m2-define-typography-config(
  $font-family: $light-y4-theme-font,
);

$light-y4-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-y4-primary,
      accent: $light-y4-accent,
      warn: $light-y4-warn,
    ),
    typography: $light-y4-typography,
  )
);
