// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin flight-informations-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // Color for grey elements
  $grey-element: #aaaaaa;

  // ----------------------------------------------

  app-flight-informations {
    width: 100%;

    .flight-informations-tab-container {
      padding: 32px;
    }

    .mat-icon {
      overflow: unset;
    }

    .mdc-tab {
      font-weight: bold;
    }

    .old-value {
      text-decoration: line-through;
      color: map-get($warn-palette, default);
    }

    .slice-status-invol {
      border-radius: 4px;
      background-color: #fceaff;
      color: #ce3fde;
      white-space: nowrap;
    }

    .choice-selection-container {
      box-shadow:
        0px 0px 4px 4px rgba(212, 212, 212, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      border: 2px solid #eaeaea;

      &:hover {
        box-shadow:
          0px 0px 4px 4px map-get($accent-palette, 100),
          0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        border: 2px solid map-get($accent-palette, 300);
      }

      &.active {
        border: 2px solid map-get($accent-palette, default);
      }

      .arrow-icon {
        &:dir(rtl) {
          transform: rotate(180deg);
        }
      }
    }

    .main-infos-container {
      font-size: 0.9rem;

      mat-icon.date-pax-separator-icon {
        color: $grey-element;
        transform: rotate(90deg);
      }
    }

    .slice-container,
    .previous-slice-container {
      box-shadow:
        0px 0px 4px 4px rgba(212, 212, 212, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      border: 2px solid #eaeaea;

      .segment-icon-container {
        display: flex;
      }

      .segment-icon {
        color: $grey-element;
        width: 0.95rem;
        height: 0.95rem;
        font-size: 0.95rem;
        text-align: center;
      }

      .flight-icon {
        color: $grey-element;
        font-size: 22px;
        transform: rotate(90deg);

        &:dir(rtl) {
          transform: rotate(-90deg);
        }
      }

      .separator {
        background-color: $grey-element;
        height: 2px;
      }
    }

    .slice-container {
      &:hover {
        box-shadow:
          0px 0px 4px 4px map-get($accent-palette, 100),
          0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        border: 2px solid map-get($accent-palette, 300);
      }
    }

    .previous-slice-container {
      background-color: rgba(212, 212, 212, 0.4);
    }

    // ----------------------------------------------
    // RESPONSIVE
    // ----------------------------------------------

    @include media-breakpoint('lt-sm') {
      .flight-informations-tab-container {
        padding: 16px;
      }

      .slice-status-invol {
        font-size: 0.85rem;
      }

      .main-infos-container {
        font-size: 0.8rem;
        flex-flow: column;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: center;
        > * {
          margin-inline-end: 0px !important;
        }

        .date-pax-separator-icon {
          display: none;
        }
      }

      .view-flight-details-container .mdc-button {
        font-size: 12px;
      }

      .timing-infos {
        font-size: 0.8rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }

      .segment-date-container {
        flex-flow: column;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: center;
        > * {
          margin-inline-end: 0px !important;
        }
      }

      .segment-airline-container {
        flex-flow: column;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: center;
        > * {
          margin-inline-end: 0px !important;
        }
      }
    }
  }
}
