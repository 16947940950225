// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin rebooking-search-alternatives-step-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // Color for grey elements
  $grey-element: #aaaaaa;

  // ----------------------------------------------

  app-rebooking-search-alternatives-step {
    height: 100%;
    width: 100%;

    .main-card {
      padding: 32px;
    }

    .card-content {
      @include media-breakpoint('lt-sm') {
        // Full-height - header height
        max-height: calc(100% - 48px);
        max-width: 100%;
      }
    }

    .alternative-container {
      box-shadow:
        0px 0px 4px 4px rgba(212, 212, 212, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      border: 2px solid #eaeaea;

      &:hover {
        box-shadow:
          0px 0px 4px 4px map-get($accent-palette, 100),
          0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        border: 2px solid map-get($accent-palette, 300);
      }

      &.active {
        border: 2px solid map-get($accent-palette, default);
      }

      .airline-logo {
        height: 20px;
      }

      .segment-icon-container {
        display: flex;
      }

      .segment-icon {
        color: $grey-element;
        width: 0.95rem;
        height: 0.95rem;
        font-size: 0.95rem;
        text-align: center;
      }

      .flight-icon {
        color: $grey-element;
        width: 0.95rem;
        height: 0.95rem;
        font-size: 0.95rem;
        transform: rotate(90deg);

        &:dir(rtl) {
          transform: rotate(-90deg);
        }
      }

      .separator {
        background-color: $grey-element;
        height: 2px;
      }
    }

    .no-alternative-container {
      padding: 32px;
    }

    // Responsive rendering

    @include media-breakpoint('lt-md') {
      .responsive-container {
        flex-flow: column;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;
        > * {
          margin-inline-start: 0px !important;
          margin-inline-end: 0px !important;
        }

        > *:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .no-alternative-container {
        padding: 16px;
      }
    }

    @include media-breakpoint('lt-sm') {
      form,
      .mat-button-toggle-group {
        width: 100%;

        .mat-button-toggle-appearance-standard {
          flex: 1 1 100%;
        }
      }

      .main-card {
        padding: 16px;
      }

      .airline-logo-container {
        display: none;
      }

      .ori-dest-container {
        display: none;
      }

      .no-alternative-container {
        padding: 0px;
      }
    }

    // Overrides (Angular Material)

    .bags-container {
      .mat-button-toggle-group {
        border: none;
        padding: 4px;
        margin: -4px;
      }

      .mat-button-toggle {
        border: 1px solid #dddddd !important;
        border-radius: 8px;
        font-weight: bold;

        .mat-pseudo-checkbox {
          --mat-minimal-pseudo-checkbox-selected-checkmark-color: map-get(
            $accent-palette,
            'default-contrast'
          );
        }
      }

      .mat-button-toggle-checked {
        background-color: map-get($accent-palette, default);
        color: map-get($accent-palette, 'default-contrast');
        border: 1px solid map-get($accent-palette, default);
      }
    }

    .alternative-date-container {
      .mat-button-toggle-group {
        border: 4px solid map-get($accent-palette, default);
        background-color: map-get($accent-palette, default);
        border-radius: 32px;
      }

      .mat-button-toggle {
        background-color: map-get($accent-palette, default);
        color: map-get($accent-palette, 'default-contrast');
        border-radius: 32px;
        padding: 0 16px;
        border-inline-start: none !important;
      }

      .mat-button-toggle-checked {
        background-color: map-get($accent-palette, 900);
        color: map-get($accent-palette, '900-contrast');
      }
    }
  }
}
