// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin navbar-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-navbar {
    z-index: 999;
    border-bottom: 1px solid #e0e0e0;

    .mat-toolbar {
      --mat-toolbar-mobile-height: 64px;
    }

    .logo {
      max-height: 64px;
    }
  }
}
