// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin home-page-theme($theme) {
  // Colors used for element backgrounds
  // Values : status-bar, app-bar, background, hover, card, dialog, disabled-button, flat-button,
  // focused-button, selected-button, disabled-button-toggle, unselected-chip, disabled-list-option, tooltip
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-home-page {
    height: 100%;

    .home-page-container {
      height: 100%;
      overflow: auto;
      padding: 32px;

      @include media-breakpoint('lt-md') {
        padding: 16px;
      }

      @include media-breakpoint('lt-sm') {
        padding: 8px;
      }

      .sub-container {
        > * {
          width: 60%;

          @include media-breakpoint('lt-lg') {
            width: 80%;
          }

          @include media-breakpoint('lt-md') {
            width: 90%;
          }

          @include media-breakpoint('lt-sm') {
            width: 100%;
          }
        }

        .error-message {
          background: map-get($warn-palette, 50);
          border: 1px solid map-get($warn-palette, default);
          border-radius: 8px;
          padding: 0px 16px;
          color: map-get($warn-palette, default);
        }

        .mat-mdc-card {
          max-width: 100%;

          .card-content {
            @include media-breakpoint('lt-sm') {
              // Full-height - header height
              max-height: calc(100% - 48px);
              max-width: 100%;
            }
          }
        }

        // Overrides (Angular Material)

        .mat-icon {
          overflow: unset;
        }
      }
    }
  }
}
