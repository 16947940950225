// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin alternative-informations-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // Color for grey elements
  $grey-element: #aaaaaa;

  // ----------------------------------------------

  app-alternative-informations {
    .mat-divider.mat-divider-inset {
      width: 100%;

      margin-inline-start: 0px;
    }

    .mat-icon {
      overflow: unset;
    }

    .travel-time {
      padding: 16px;
      // Override
      padding-inline-end: 0px;
      margin-inline-start: 5px;
      border-inline-start: 2px solid $grey-element;
    }

    .airline-logo {
      height: 20px;
    }

    .mdc-tab {
      font-weight: bold;
    }

    .itinerary-information {
      margin-inline-start: 9px;
    }

    .itinerary-separator,
    .layover-separator {
      margin-inline-start: 20px;
      border-inline-start: 2px dashed map-get($accent-palette, default);
    }

    .reversed-icon {
      transform: rotate(180deg);
    }

    .layover-icon {
      color: map-get($accent-palette, default);
      margin-inline-start: 9px;
    }

    .layover-text {
      line-height: 24px;
    }

    .segment-icon {
      color: $grey-element;
      width: 0.75rem;
      height: 0.75rem;
      font-size: 0.75rem;
      text-align: center;
    }

    .origin-border {
      padding-inline-start: 20px;
      margin-inline-start: 5px;
      border-inline-start: 2px solid $grey-element;
    }

    .destination-border {
      margin-inline-start: 25px;
    }

    .segment-mat-card {
      box-shadow:
        0px 0px 3px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 3px 0px rgba(0, 0, 0, 0.12);
    }

    // RESPONSIVE

    @include media-breakpoint('lt-sm') {
      .outbound {
        flex: 1 1 70%;
      }

      .outbound-date {
        flex: 1 1 30%;
        text-align: right;
      }
    }
  }
}
