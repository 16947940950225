// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin hyperwallet-refund-step-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-hyperwallet-refund-step {
    height: 100%;
    width: 100%;

    .main-card {
      padding: 32px;
    }

    #TransferMethodUI {
      min-height: 425px;

      fieldset {
        padding: 0;
        border: 0;
        margin: 0;
      }
    }

    #hw-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.15);
      z-index: 9999;
      inset: 0;
      border-radius: 8px;
    }

    .hw-form-transfer-methods {
      .hw-fields {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 8px;

        .hw-label,
        .hw-number,
        .hw-input,
        .hw-select {
          width: 100%;
          max-height: 48px;
          padding: 16px;
          margin: 16px 0px;
          border-radius: 8px;
          border-color: #9e9e9e;
          outline: #9e9e9e auto 1px;

          &:hover {
            border-color: black;
            outline: black auto 1px;
          }

          &:focus,
          &:active,
          &:focus-visible {
            border-color: map-get($accent-palette, default);
            outline: map-get($accent-palette, default) auto 1px;
          }
        }

        .hw-error-message {
          width: 100%;
          height: 2rem;
          margin-bottom: 8px;
          color: map-get($warn-palette, default);
        }
      }

      .hw-field-headers {
        font-size: 16px;
        margin-top: 16px;
      }
    }

    .hw-custom-transfermethodtype-items {
      box-shadow:
        0px 0px 3px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 3px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      width: 100%;
      background: transparent;
      border-radius: 8px;
      border: none;
      padding: 16px;
      display: flex;
      flex-flow: row;
      box-sizing: border-box;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      > *:not(:last-child) {
        margin-inline-end: 16px;
      }

      * {
        text-align: start;
      }

      &:hover {
        box-shadow:
          0px 0px 4px 0px rgba(0, 0, 0, 0.2),
          0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        border-color: map-get($accent-palette, default);
      }

      .hw-custom-transfermethodtype-images {
        margin: 0px 0px 0px 8px !important;
      }
    }

    .hw-global-errors {
      background: map-get($warn-palette, 50);
      border: 1px solid map-get($warn-palette, default);
      border-radius: 8px;
      padding: 0px 16px;
      color: map-get($warn-palette, default);
      margin: 16px;

      .hw-global-errors-list {
        list-style: none;
        padding-inline-start: 0px;
      }
    }

    .hw-tmc-back-button,
    .hw-tmc-submit-button {
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px !important;
      cursor: pointer;
      border: none;
      min-width: 64px;
      line-height: 48px;
      padding: 0px 16px;
      width: 80%;
      margin: 8px 10%;
    }

    .hw-tmc-submit-button {
      background-color: map-get($accent-palette, default);
      color: map-get($accent-palette, 'default-contrast');

      p {
        margin: 0px;
      }
    }

    // Responsive rendering

    @include media-breakpoint('lt-sm') {
      .card-content {
        // Full-height - header height
        max-height: calc(100% - 48px);
        max-width: 100%;
      }

      .main-card {
        padding: 16px;
      }
    }
  }
}
