// -----------------------------------------------
// Layout
// -----------------------------------------------

.fx-row {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  flex-grow: 1;
}

.fx-row-wrap {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  flex-grow: 1;
}

.fx-column {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  flex-grow: 1;
}

.fx-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

// -----------------------------------------------
// Alignment
// -----------------------------------------------

@each $property,
  $abbrev
    in (
      flex-start: start,
      flex-end: end,
      center: center,
      space-around: space-around,
      space-between: space-between,
      stretch: stretch
    )
{
  @each $property2,
    $abbrev2
      in (
        flex-start: start,
        flex-end: end,
        center: center,
        space-around: space-around,
        space-between: space-between,
        stretch: stretch
      )
  {
    .fx-r-#{$abbrev}-#{$abbrev2} {
      justify-content: $property;
      align-content: $property2;
      align-items: $property2;
    }

    .fx-c-#{$abbrev}-#{$abbrev2} {
      align-content: $property;
      align-items: $property;
      justify-content: $property2;
    }
  }
}

// -----------------------------------------------
// Gap (0 to 128) - step: 2
// -----------------------------------------------

@for $index from 0 through 64 {
  $size: $index * 2;
  $length: #{$size}px;

  .fx-row.fx-gap-#{$size},
  .fx-row-wrap.fx-gap-#{$size} {
    > *:not(:last-child) {
      margin-inline-end: $length;
    }
  }

  .fx-column.fx-gap-#{$size} {
    > *:not(:last-child) {
      margin-bottom: $length;
    }
  }
}

// -----------------------------------------------
// Flex width/height (1 to 100%) - step: 1
// -----------------------------------------------

@for $index from 1 through 100 {
  $length: #{$index};

  .fx-r-#{$length} {
    flex: 1 1 100%;
    max-width: #{$length} + '%';
  }

  .fx-c-#{$length} {
    flex: 1 1 100%;
    max-height: #{$length} + '%';
  }
}

.fx-auto {
  flex: 1 1 100%;
}
