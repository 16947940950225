// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin layout-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-layout {
    height: 100%;

    .layout-container {
      height: 100%;
      background-color: #f2f2f2;

      .loader-container {
        background-color: white;
        position: fixed;
        z-index: 9999;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition:
          visibility 500ms linear,
          opacity 500ms linear;
      }

      app-navbar {
        height: 64px;
        max-height: 64px;
      }

      .content {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }
}
