@use '@angular/material' as mat;

$light-j9-primary-palette: (
  50: #e1eef8,
  100: #b3d5ec,
  200: #81b9e0,
  300: #4e9dd4,
  400: #2888ca,
  500: #0273c1,
  600: #026bbb,
  700: #0160b3,
  800: #0156ab,
  900: #01439e,
  A100: #cce9ff,
  A200: #99d3ff,
  A400: #66bdff,
  A700: #4cb2ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$light-j9-accent-palette: (
  50: #fff8e1,
  100: #ffefb3,
  200: #ffe481,
  300: #ffd94f,
  400: #ffd029,
  500: #ffc803,
  600: #ffc203,
  700: #ffbb02,
  800: #ffb402,
  900: #ffa701,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff2e8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$light-j9-primary: mat.m2-define-palette(
  $light-j9-primary-palette,
  500,
  300,
  700
);
$light-j9-accent: mat.m2-define-palette(
  $light-j9-accent-palette,
  500,
  300,
  700
);
$light-j9-warn: mat.m2-define-palette(mat.$m2-red-palette);

@font-face {
  font-family: 'Avenir';
  src: url(../../../../assets/themes/theme-light-j9/Avenir/Avenir-Black.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Avenir';
  src: url(../../../../assets/themes/theme-light-j9/Avenir/Avenir-Medium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(../../../../assets/themes/theme-light-j9/Avenir/Avenir-Light.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: 'J9';
  src: url(../../../../assets/themes/theme-light-j9/J9/J9-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'J9';
  src: url(../../../../assets/themes/theme-light-j9/J9/J9-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'J9';
  src: url(../../../../assets/themes/theme-light-j9/J9/J9-Light.ttf);
  font-weight: lighter;
}

$light-j9-theme-font: 'Avenir';
$light-j9-theme-font-rtl: 'J9';

$light-j9-typography: mat.m2-define-typography-config(
  $font-family: $light-j9-theme-font,
);

$light-j9-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-j9-primary,
      accent: $light-j9-accent,
      warn: $light-j9-warn,
    ),
    typography: $light-j9-typography,
  )
);
