// -----------------------------------------------------------------------------------------------------
// @ Spacing helpers [m | p] [x? | y?] - [0-4-8-12-...-256 | auto]
// -----------------------------------------------------------------------------------------------------

@each $prop, $abbrev in (margin: m, padding: p) {
  @for $index from 0 through 64 {
    $size: $index * 4;
    $length: #{$size}px;

    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }

  @for $index from 0 through 64 {
    $size: $index * 4;
    $length: #{$size}px;

    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
      #{$prop}-left: $length !important;
    }

    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
      #{$prop}-bottom: $length !important;
    }
  }

  @for $index from 0 through 64 {
    $size: $index * 4;
    $length: #{$size}px;

    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $length !important;
    }
  }

  @if ($abbrev == m) {
    // Some special margin utils for flex alignments
    .m-auto {
      margin: auto !important;
    }

    .mt-auto {
      margin-top: auto !important;
    }

    .mr-auto {
      margin-right: auto !important;
    }

    .mb-auto {
      margin-bottom: auto !important;
    }

    .ml-auto {
      margin-left: auto !important;
    }

    .mx-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .my-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Font helpers
// -----------------------------------------------------------------------------------------------------

.bold {
  font-weight: bold;
}

.italic {
  font-weight: italic;
}

.capitalize {
  text-transform: capitalize;
}

// -----------------------------------------------------------------------------------------------------
// @ Alignment helpers
// -----------------------------------------------------------------------------------------------------

.start {
  text-align: start;
}

.end {
  text-align: end;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

// -----------------------------------------------------------------------------------------------------
// @ General helpers
// -----------------------------------------------------------------------------------------------------

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.hidden {
  display: none !important;
}

.small-text {
  font-size: 0.75rem;
}

.secondary-text {
  font-size: 0.85rem;
  color: #808080;
}

.secondary-text-black {
  font-size: 0.85rem;
  color: #000000;
}

.large-button {
  padding: 24px 48px !important;
}

.grow-1 {
  flex-grow: 1;
}

.grow-2 {
  flex-grow: 2;
}
